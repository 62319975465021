
/**
 * BootstrapModal Object
 *
 * Use this object to create an abstracted
 * version of the Bootstrap Modal
 *
 * @param element
 * @constructor
 */
var BootstrapModal = function(element)
{
    // Get the bootstrap modal
    this.bsm            = element || jQuery('#modal');
    this.bsm_container  = this.bsm.find('.modal-dialog');
    this.bsm_header     = this.bsm.find('.modal-header');
    this.bsm_title      = this.bsm.find('.modal-header h4');
    this.bsm_body       = this.bsm.find('.modal-body');
    this.bsm_footer     = this.bsm.find('.modal-footer');

    // Set unique values on primary and secondary buttons
    this.bsm.find('.btn-primary').addClass('primary-action');
    this.bsm.find('.btn-default').addClass('secondary-action');

    this.bsm_primary = this.bsm.find('.primary-action');
    this.bsm_secondary = this.bsm.find('.secondary-action');

    // Reset the modal for use
    this.resetModal();
};

BootstrapModal.prototype.setOptions = function(options)
{
    this.options = options;

    return this;
};

/**
 * Reset Modal function
 *
 * Use this function to reset all the
 * BootstrapModal properties back to their defaults
 */
BootstrapModal.prototype.resetModal = function()
{
    this.bsm_container
        .removeClass('large-modal')
        .removeClass('modal-lg')
        .removeClass('modal-md')
        .removeClass('modal-sm');
    this.bsm_title.html('');
    this.bsm_body.html('');
    this.bsm_body.css({"min-height" : "100px"});
    this.bsm_primary
        .removeClass('disabled')
        .removeClass('btn-danger')
        .removeClass('btn-info')
        .removeClass('btn-warning')
        .removeClass('btn-success')
        .addClass('btn-primary')
        .attr("href", '#')
        .removeAttr('target')
        .html('Ok')
        .unbind("click");
    this.bsm_footer.hide();
};

BootstrapModal.prototype.submit = function() {
    var modal = this;
    var options = modal.options;
    var form = this.bsm_body.find('form');

    if (typeof form.attr('id') !== undefined) {
        _id = '#' + form.attr('id');
        if ($(_id).attr('data-manual-trigger') && $(_id).data('manual-trigger') != 1) {
            $.event.trigger(options.triggers.manual || "modalManualTrigger", [modal]);
            return false;
        }
    }

    var primary_button = this.bsm_primary;
    var form_url   = form.attr("action");
    var form_data  = new FormData(form[0]);
    var successMsg = options.messages.success;
    var errorMsg   = options.messages.error;

    // De-active the button until success or failed
    primary_button.html(options.primaryButtonSaving || 'Saving').addClass('disabled');

    // Setup validation
    form.validate({
        ignore : ":not(select:hidden, input:hidden, input:visible, textarea:visible)"
    });

    if (form.valid()) {

        // Setup the ajax call
        $.ajax({
            url         : form_url,
            type        : 'POST',
            data        : form_data,
            contentType : false,
            processData : false,
            success     : function(result)
            {
                // Show Tick on complete
                primary_button.html('<i class="mdi-action-done"></i>');

                if (successMsg !== undefined) {
                    App.bootstrap.notifyUser(successMsg, 'success');
                }

                modal.bsm_body.html('<div class="alert alert-success">' + successMsg + '</div>');

                if (options.closeOnSuccess) {
                    modal.bsm_footer.hide();
                    modal.bsm.modal('toggle');
                }

                // Check if success trigger is a closure
                if (options.triggers.success && typeof options.triggers.success == 'function') {
                    options.triggers.success(result, modal);
                } else {

                    // Fire the success event
                    var successEvents = options.triggers.success || "modalSuccess";

                    successEvents.split(' ').forEach(function (successEvent) {
                        $.event.trigger(successEvent, [result, modal]);
                    });
                }
            },
            error       : function(result)
            {
                // Return button to active
                primary_button.html(options.primaryButtonHtml || 'Save').removeClass('disabled');

                if (options.messages.failed !== undefined) {
                    App.bootstrap.notifyUser(options.messages.failed, 'danger');
                }

                var errors = false;

                if (result.responseJSON !== undefined && typeof result.responseJSON.errors !== 'undefined') {
                    errors = result.responseJSON.errors;
                } else {
                    message = jQuery.parseJSON(result.responseText);

                    if (message.errors !== undefined) {
                        errors = message.errors;
                    }
                }

                if (errors) {

                    $.each(errors, function(key, error)
                    {
                        App.log('Error: ' + error);
                        App.bootstrap.notifyUser('Error: <strong>' + error + '</strong>', 'danger');
                    });

                } else {
                    App.log('Error: ' + errorThrown);
                    App.bootstrap.notifyUser('Error: <strong>' + errorThrown + '</strong>', 'danger');
                    modal.bsm_body.html('<div class="alert alert-danger">' + errorMsg + '</div>');
                }

                // Check if fail trigger is a closure
                if (options.triggers.fail && typeof options.triggers.fail == 'function') {
                    options.triggers.fail(result, modal);
                } else {
                    // Fire the failed event
                    $.event.trigger(options.triggers.fail || "modalFailed", [result, modal]);
                }
            }
        });

    } else {
        return false;
    }
};
