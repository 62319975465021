/**
 * App redactor Object
 *
 * @type object
 */
App.redactor = function()
{

    /**
     * Object properties
     *
     * @type {App}
     */
    var _this = this;


    /**
     * Constructor
     *
     * Booting function
     *
     * @param element string
     */
    this.init = function(element)
    {
        this.createNewInstance(element);
        return this;
    };


    /**
     * Handle Basic redactor
     */
    this.createBasicNewInstance = function(element)
    {
        $(element).redactor({
            replaceDivs     : false,
            removeEmptyTags : false,

            plugins : ['alignment'],

            buttons : [
                'html', 'format', 'bold', 'italic', 'deleted',
                'lists',
                'link', 'alignment', 'horizontalrule'
            ]
        });
    }


    /**
     * Handle redactor
     */
    this.createNewInstance = function(element)
    {
        // Get the controller URL and initialize redactor
        var url         = $(element).data("url");
        var documentUrl = $(element).data("document-url");

        this.redactorObject = $(element).redactor({
            plugins           : ['advanced', 'advancedvideo', 'table', 'fontcolor', 'alignment', 'source', 'definedlinks'],
            focus             : false,
            minHeight         : 350,
            maxHeight         : 350,
            replaceDivs       : false,
            convertVideoLinks : true,
            removeEmptyTags   : false,
            toolbarFixed      : false,
            imageResizable    : true,
            imagePosition     : true,
            buttons           : [
                'source', 'format', 'bold', 'italic', 'deleted',
                'lists', 'image',
                'link', 'alignment', 'horizontalrule', // 'file',
            ],

            imageGetJson       : url + 'list',
            clipboardUploadUrl : url + 'copy',
            imageUpload        : url + 'upload',
            definedLinks       : documentUrl,
            imageUploadFields  : {'_token' : App.helper.getToken()}
        });

        return this;
    };


    /**
     * Custom Video Plugin
     */
    this.handleAdvancedVideo = function(videos)
    {
        $.Redactor.prototype.advancedvideo = function()
        {
            if (!videos.length) {
                return false;
            }

            return {
                init : function()
                {
                    var dropdown = {};

                    $.each(videos, function(i, item)
                    {

                        dropdown[i]       = {};
                        dropdown[i].title = item.name;

                        dropdown[i].func = function()
                        {
                            this.insert.html('<p>[VIDEO_' + item.id + ']</p>');
                        };

                    });

                    var button = this.button.add('advancedvideo', '');
                    this.button.addDropdown(button, dropdown);
                }
            };
        };
    };
};
