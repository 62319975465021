/**
 * Programmatically trigger a modal with the given options
 *
 * Options structure
 *
 * options = {
 *   title,
 *
 *   hidePrimary,
 *   primaryButtonHtml,
 *
 *   overflow,
 *   modalSize,
 *   modalClasses,
 *
 *   ajaxUrl,
 *   triggers: {
 *       loaded,
 *       manual,
 *       success,
 *       fail,
 *   },
 *   messages: {
 *       success,
 *       error,
 *       failed
 *   }
 *};
 */
function triggerModalWithOptions(options, trigger)
{
    // Verify some required options
    if (options.ajaxUrl === undefined) {
        throw "Required modal option missing [ajaxUrl]";
    }

    if (options.triggers === undefined) {
        options.triggers = {};
    }

    if (options.messages === undefined) {
        options.messages = {};
    }

    // Create the Bootstrap Modal
    var AbstractModal = new BootstrapModal();
    AbstractModal.setOptions(options);
    AbstractModal.trigger = trigger || null;

    // Scroll to top as this is where the modal loads
    $(document).scrollTop(0);

    // Change the Title
    AbstractModal.bsm_title.html(options.title || 'Modal');

    // Set the modal primary and secondary buttons
    AbstractModal.bsm_primary
        .removeClass('btn-default')
        .addClass('btn-success')
        .html(options.primaryButtonHtml || 'Save');

    // Set the modal primary and secondary buttons
    AbstractModal.bsm_secondary
        .removeClass('btn-danger')
        .addClass(options.secondaryButtonClass || 'btn-default')
        .html(options.secondaryButtonHtml || 'Cancel');

    if(options.secondaryButtonAction) {
        AbstractModal.bsm_secondary.removeAttr('href').removeAttr('data-dismiss');
    }

    if (options.hidePrimary == true) {
        AbstractModal.bsm_primary.addClass('hidden');
    }

    // Allow overflow
    if (options.overflow == true) {
        AbstractModal.bsm_body.css({"overflow" : "visible"});
        AbstractModal.bsm_body.parent().css({"overflow" : "visible"});
    }

    // Allow modal size configuration
    if (options.modalSize) {
        AbstractModal.bsm_container.addClass('modal-' + options.modalSize);
    }

    // Allow modal classes to be added
    if (options.modalClasses) {
        AbstractModal.bsm_container.addClass(options.modalClasses);
    }

    // Load the form
    $.ajax({
        url     : options.ajaxUrl,
        type    : 'GET',
        headers : {"X-MODAL" : true},
    }).done(function(response)
    {
        AbstractModal.bsm_body.html(response);

        // Check if loaded trigger is a closure
        if (options.triggers.loaded && typeof options.triggers.loaded == 'function') {
            options.triggers.loaded(response, AbstractModal);
        } else {
            // Fire the loaded event
            var loadedEvents = options.triggers.loaded || "modalLoaded";

            loadedEvents.split(' ').forEach(function(loadedEvent)
            {
                $.event.trigger(loadedEvent, [response, AbstractModal]);
            });
        }
    });

    // Show the footer and the modal
    AbstractModal.bsm_footer.show();
    AbstractModal.bsm.modal('show');

    // If the user clicks the primary button
    AbstractModal.bsm_primary.on('click', function(e)
    {
        e.preventDefault();

        AbstractModal.submit();
    });


    if (options.secondaryButtonAction) {

        // If the user clicks the primary button
        AbstractModal.bsm_secondary.on('click', function(e)
        {
            e.preventDefault();
            $.ajax({
                url     : options.secondaryButtonAction,
                type    : 'GET',
                success : function(result)
                {
                    if (options.secondarySuccessMsg !== undefined) {
                        App.bootstrap.notifyUser(options.secondarySuccessMsg, 'success');
                    }

                    if (options.closeOnSuccess) {
                        AbstractModal.bsm_footer.hide();
                        AbstractModal.bsm.modal('toggle');
                    }

                },
                error   : function(result)
                {
                    AbstractModal.bsm_body.html('<div class="alert alert-danger">' + errorMsg + '</div>');
                }
            });

        });
    }

    return AbstractModal;
}

/**
 * Handle abstract modal function
 *
 * Use this function to create a dynamic model
 * via data attributes, to use simply add a class of
 * "open-model" to a button or link, available data
 * attributes are:
 *
 * "title"      - modal title
 * "overflow"   - allow modal body to overflow
 * "url"        - the url of the form to load into the body
 * "success"    - Message to display on success.
 * "failed"     - message to display on fail
 *
 */
function handleAbstractModal()
{
    $(document).on('click', '.open-modal', function(e)
    {
        // Prevent the default action
        e.preventDefault();

        var object = $(this);

        triggerModalWithOptions({
            title : object.data('title'),

            hidePrimary         : object.data('hide-primary'),
            primaryButtonHtml   : object.data('button'),
            secondaryButtonHtml : object.data('secondary-button'),

            secondaryButtonClass  : object.data('secondary-button-class'),
            secondaryButtonAction : object.data('secondary-button-action'),
            secondarySuccessMsg   : object.data('secondary-success'),

            overflow       : object.data('overflow'),
            modalSize      : object.data('modal-size'),
            modalClasses   : object.data('modal-classes'),
            closeOnSuccess : object.data('close-on-success') || true,

            ajaxUrl  : object.data('url'),
            triggers : {
                loaded  : object.data('load-trigger'),
                manual  : object.data('manual-trigger'),
                success : object.data('success-trigger'),
                fail    : object.data('fail-trigger'),
            },
            messages : {
                success : object.data('success'),
                error   : object.data('error'),
                failed  : object.data('failed')
            }
        }, object);

        // Return false to stop the browser from loading the link
        return false;
    });
}


/**
 * Handle delete modal function
 *
 * Use this function to show a "Are you sure" prompt
 * when the user clicks on a element with the class of "delete"
 *
 */
function handleDeleteModal(type, message)
{
    $(document).on('click', '.delete, .delete-selected', function(e)
    {
        // Prevent the default action
        e.preventDefault();

        // Create the Bootstrap Modal
        var delete_btn  = $(this),
            url         = delete_btn.attr('href'),
            return_url  = delete_btn.data('return-url') ? delete_btn.data('return-url') : $(location).attr('href'),
            DeleteModal = new BootstrapModal(),
            isAjax      = delete_btn.data('is-ajax') != false,
            reloadTable = delete_btn.data('reload-table'),
            recordName  = delete_btn.data('name') ? delete_btn.data('name') : "Record",
            successMsg  = delete_btn.data('success') ? delete_btn.data('success') : "Delete Complete!",
            errorMsg    = delete_btn.data('error') ? delete_btn.data('error') : "Delete Failed",
            title       = delete_btn.hasClass('delete-selected') ? 'Delete ' + recordName + '?' : 'Delete Record?',
            message     = delete_btn.hasClass('delete-selected') ? 'Are you sure you want to delete these records?' : 'Are you sure you want to delete this record?';

        // Set title and footer
        DeleteModal.bsm_title.html(title);
        DeleteModal.bsm_body.html(message);
        DeleteModal.bsm_footer.show();

        // Set the modal primary and secondary buttons
        DeleteModal.bsm_primary
            .removeClass('btn-primary')
            .addClass('btn-danger')
            .html('Delete');

        // Show the modal
        DeleteModal.bsm.modal('show');

        // If the user clicks the delete button
        DeleteModal.bsm_primary.on('click', function(e)
        {
            if (delete_btn.hasClass('delete-selected')) {
                delete_btn.closest('form').submit();
                return true;
            }

            // Prevent the default action
            if (isAjax) {
                e.preventDefault();

            } else {
                window.location = url;
                return null;
            }

            // Perform the Ajax request
            $.ajax({
                url     : url,
                type    : 'GET',
                cache   : false,
                success : function(result)
                {

                    // if set reload datatable and not whole page
                    if (reloadTable) {
                        DeleteModal.bsm.modal('hide');
                        App.bootstrap.notifyUser(successMsg, 'success');
                        delete_btn.closest('table').dataTable().fnDraw();
                        return;
                    }

                    if (result.return_url !== undefined) {
                        window.location.href = result.return_url;
                        return;
                    }

                    if (result.error !== undefined) {
                        console.log(result.error);
                        DeleteModal.bsm.modal('hide');
                        App.bootstrap.notifyUser(result.error, 'danger');

                        return false;

                    } else {
                        App.bootstrap.notifyUser(successMsg, 'success');
                        document.location.href = return_url;
                    }

                    // Notify and refresh content
                    $('#middle').load(window.location.pathname + ' #middle > *', function(element)
                    {
                        App.bootstrap.handleTab();
                        DeleteModal.bsm.modal('hide');

                        // Fire the loaded event
                        $.event.trigger("contentReloaded", [delete_btn]);
                    });
                },
                fail    : function(result)
                {
                    DeleteModal.bsm.modal('hide');
                    App.bootstrap.notifyUser(errorMsg, 'danger');
                    DeleteModal.bsm_body.html('Delete failed ');
                }
            });

            // Unbind the previous click event
            DeleteModal.bsm_primary.unbind("click");

            return false;
        });

        // Return false to stop the browser from loading the link
        return false;
    });
}


/**
 * Handle the confirmation modal events
 */
function handleConfirmationModal()
{
    $(document).on('click', '.confirm :not(.sa-confirm-button-container)', function(e)
    {
        e.preventDefault();
        displayConfirmationModal(($(this)));
    });

    $(document).on('onSubmit', 'form.confirm', function(e)
    {
        e.preventDefault();
        displayConfirmationModal($(this));
    });
}


/**
 * Display confirmation modal
 *
 * @param selector
 */
function displayConfirmationModal(selector)
{
    var title   = $(selector).data('confirm-title') || 'Are you sure you wish to continue?';
    var message = $(selector).data('confirm-message') || '';
    var icon    = $(selector).data('confirm-icon') || 'mdi-alert-warning';

    App.confirm.init();
    App.confirm.element(selector);
    App.confirm.icon(icon);
    App.confirm.title(title);
    App.confirm.message(message);
    App.confirm.show();
}

/**
 * Document ready functions
 */
$(document).ready(function()
{

});


/**
 * Window load functions
 */
$(window).load(function()
{
    // Handle boot functions
    handleAbstractModal();
    handleDeleteModal();
    handleConfirmationModal();
});


/**
 * Ajax start functions
 */
$(document).ajaxStart(function(data)
{

});


/**
 * Ajax stop functions
 */
$(document).ajaxStop(function(data)
{

});
