/**
 * App helper Object
 *
 * @type object
 */
App.helper = {

    /**
     * Object properties
     */
    csrfToken: null,


    /**
     * Constructor Function
     *
     * Boot function
     */
    init: function() {

    },


    /**
     * Set CSRF token
     *
     * @param token
     */
    setToken: function(token) {
        this.csrfToken = token;
    },


    /**
     * Get CSRF token
     *
     * @returns {*}
     */
    getToken: function() {
        if (this.csrfToken !== null) {
            return this.csrfToken;
        }
        return $('input[name="_token"]').val();
    },


    /**
     * Get word count function
     *
     * @param html
     * @returns {number|jQuery}
     */
    getWordCount : function(html)
    {
        // Remove any HTML tags, newlines and tabs, split by spaces and then count
        var count = $(html).text().replace(/\t+/g, " ").replace(/\n/g, "").split(' ').length;

        if ($(html).text().length) {
            // If there are HTML tags, remove them along with newlines and tabs, split by spaces and then count
            count = $(html).text().replace(/\t+/g, " ").replace(/\n/g, "").split(' ').length;

        } else {
            // Otherwise, just remove newlines and tabs, split by spaces and then count
            count = html.replace(/\t+/g, " ").replace(/\n/g, "").split(' ').length;
        }
        return count;
    },


    /**
     * Convert string to lowercase
     */
    strtolower : function(string)
    {
        return string.toLowerCase();
    },


    /**
     * Convert string to upper case
     */
    strtoupper : function(string)
    {
        return string.toUpperCase();
    },


    /**
     * Converts first letter to upper case
     */
    ucfirst : function(string)
    {
        return string.replace(/(\b)([a-zA-Z])/, function(firstLetter){
            return firstLetter.toUpperCase();
        });
    },


    /**
     * Converts the first letter of each word to upper case
     */
    ucwords : function(string)
    {
        return string.replace(/(\b)([a-zA-Z])/g, function(firstLetter){
          return firstLetter.toUpperCase();
        });
    },


    /**
     * Format currency
     *
     * @param value
     * @returns {string}
     */
    formatCurrency : function (value) {
        return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },


    /**
     * Unformat currency
     *
     * @param value
     * @returns {*}
     */
    unformatCurrency : function (value) {
        return value.replace(/[^0-9\.]+/g, '');
    },


    /**
     * Check if an item is in an array
     *
     * @param needle
     * @param haystack
     * @returns {boolean}
     */
    in_array : function(needle, haystack)
    {
        if ($.inArray(needle, haystack) !== -1) {
            return true;
        }

        return false;
    },


    /**
     * Check if the object value is empty
     *
     * @param value
     * @returns {boolean}
     */
    empty : function(value)
    {
        if (value == '' || value == 'undefined' || value == null) {
            return true;
        }

        return false;
    },


    /**
     * Check if the object value is empty
     *
     * @returns {string}
     */
    getPath : function(element)
    {
        var path, node = element;
        while (node.length) {
            var realNode = node[0], name = realNode.localName;
            if (!name) break;
            name = name.toLowerCase();

            var parent = node.parent();

            var sameTagSiblings = parent.children(name);
            if (sameTagSiblings.length > 1) {
                allSiblings = parent.children();
                var index = allSiblings.index(realNode) + 1;
                if (index > 1) {
                    name += ':nth-child(' + index + ')';
                }
            }

            path = name + (path ? '>' + path : '');
            node = parent;
        }

        return path;
    }
};

Object.defineProperty(Array.prototype, 'clean', {
    enumerable: false,
    value: function(deleteValue) {
        for (var i = 0; i < this.length; i++) {
            if (this[i] == deleteValue) {
                this.splice(i, 1);
                i--;
            }
        }
        return this;
    }
});