/**
 * App checkbox Object
 *
 * @type object
 */
App.checkbox = {

    selected: [],

    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {
        this.checkAll();
        this.handleChecked();
    },


    /**
     * Handle the check all functionality
     */
    checkAll : function()
    {
        $(document).on('click', '[data-action="check-all"] [name="all"]', function() {
            $(this).closest('[data-action="check-all"]').find('[type="checkbox"]').not('[disabled]').not(this).prop('checked', this.checked);
        });
    },


    /**
     * Handle the checked items that are no longer on the page (datatables)
     */
    handleChecked: function()
    {
        $(document).on('change', '.remember-checkboxes input[type="checkbox"]', function() {

            $('.remember-checkboxes').each(function(i) {

                var datatable = $(this).data('key') ? $(this).data('key') : i;

                $(this).find('[data-label]').each(function() {

                    if (App.checkbox.selected[datatable] === undefined) {
                        App.checkbox.selected[datatable] = [];
                    }

                    App.removeValueFromArray(App.checkbox.selected[datatable], $(this).val());

                    if ($(this).is(':checked')) {
                        App.checkbox.selected[datatable].push($(this).val());
                    }

                });
            });
        });

        $(document).on('datatable.change', function() {
            $('.remember-checkboxes').each(function(i) {

                var datatable = $(this).data('key') ? $(this).data('key') : i;

                $(this).find('[data-label]').each(function() {
                    if (App.checkbox.selected[datatable] !== undefined && App.checkbox.selected[datatable].indexOf($(this).val()) != -1) {
                        $(this).prop("checked", true);
                    }
                });
            });
        });

    },

};