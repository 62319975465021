/**
 * App Jquery Object
 *
 * @type object
 */
App.jquery = {

    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {
        App.jquery.handleDataTables();
        App.jquery.handleRedactor();
        App.jquery.handleValidator();
        App.jquery.handleInputMask();
    },


    /**
     * Handle validator
     */
    handleValidator : function()
    {
        // Setup the Jquery Validation Defaults
        $.validator.setDefaults({
            doNotHideMessage : true,          // show the error/success messages on tab switch.
            errorElement     : 'span',        // default input error message container
            errorClass       : 'error-popup', // default input error message class
            focusInvalid     : false,         // do not focus the last invalid input

            errorPlacement : function(error, element)
            {
                if (element.parent('.input-group').length) {
                    error.insertAfter(element.parent());

                } else {
                    error.insertAfter(element);
                }
            },

            highlight : function(element)
            {
                $(element)
                    .closest('.form-group')
                    .removeClass('has-success')
                    .addClass('has-error');
            },

            unhighlight : function(element)
            {
                if ($(element).closest('.form-group').hasClass('has-error')) {
                    $(element)
                        .closest('.form-group')
                        .removeClass('has-error')
                        .addClass('has-success');
                }
            },

            invalidHandler : function(event, validator)
            {
                App.window.scrollTo($('form'), -200);
            },

            onfocusout : function(element)
            {
                $(element).valid();
            },

            onchange : function(element)
            {
                $(element).valid();
            }
        });
    },


    /**
     * Refresh all data tables
     */
    refreshDataTables : function()
    {
        var tables = $('.table');

        // If the table has a URL add the POST function
        $.each(tables, function(index, value) {
            if ($(value).data('ajax')) {
                $(value).DataTable().clear().ajax.reload(null, false);
            }
        });

        return this;
    },


    /**
     * Handle data tables
     *
     * @param selector
     */
    handleDataTables : function(selector)
    {
        // If the plugin is not loaded then skip
        if (!jQuery().DataTable()) {
            return;
        }

        var tablesSelector = (typeof selector !== 'undefined') ? selector : '.data-tables';
        var tables         = $(tablesSelector);

        // Loop through tables and create data table object
        $.each(tables, function(index, table) {
            if (!$.fn.DataTable.isDataTable($(this))) {
                var dataTable = new App.dataTable().init(table);
                if ($(this).data('sortable')) {
                    $(this).rowReordering({
                        sURL: $(this).data('sortable')
                    });
                }
            }
        });
    },


    /**
     * Handle masked form inputs
     */
    handleInputMask : function()
    {
        $('.mask-time').mask('00:00:00');
        $('.mask-date').mask('11/11/1111');
        $('.mask-date-time').mask('00/00/0000 00:00:00');
        $('.mask-money').mask('000.000.000.000.000,00', {reverse : true});
        $('.mask-number').mask("#,##0", {reverse : true, maxlength : false});
        $('.mask-phone').mask('000000000000000');
        $('.mask-mobile').mask('000000000000000');
        $('.mask-postcode').on('keyup', function()
        {
            $(this).val($(this).val().toUpperCase());
        });
    },

    /**
     * Handle redactor
     */
    handleRedactor : function()
    {
        // Element Selector
        var wysiwyg = $('.hasRedactor');
        var newRedactors = [];

        if (wysiwyg.length > 0) {

            // Loop through tables and create data table object
            $.each(wysiwyg, function(index, element)
            {
                if(!$(element).hasClass('instantiated-redactors')) {
                    newRedactors[index] = new App.redactor().init($(element));
                    $(element).addClass('instantiated-redactors')
                }


            });
        }

        // Basic Element Selector
        var wysiwygBasic = $('.hasBasicRedactor');
        var newBasicRedactors = [];

        if (wysiwygBasic.length > 0) {

            // Loop through tables and create data table object
            $.each(wysiwygBasic, function(index, element)
            {
                if(!$(element).hasClass('instantiated-redactors')) {
                    newBasicRedactors[index] = new App.redactor().createBasicNewInstance($(element));
                    $(element).addClass('instantiated-redactors')
                }
            });
        }

    },

};


/**
 * Window load functions
 *
 * Once the window has
 * loaded create the object
 */
$(document).ready(function() {
    App.jquery.init();
});


/**
 * Ajax stop functions
 */
$(document).ajaxStop(function(data) {
//    App.jquery.init();
});
