var AppDataTableState;

/**
 * App dataTable Object
 *
 * @type object
 */
App.dataTable = function() {

    /**
     * Constructor
     *
     * Booting function
     *
     * @param form string
     */
    this.init = function(element) {
        this.table = $(element);

        // Event Listener
        this.events(element);

        this.dataTable = this.table.DataTable(this.setOptions());

        this.handleSiteFilter();
        this.handleFormFilter();

        return this.dataTable;
    };


    /**
     * Set the page length
     */
    this.setPageLength = function() {
        return this.table.data('page-length') !== undefined ? this.table.data('page-length') : 50;
    };


    /**
     * Set the available page options
     *
     * @returns {*[]}
     */
    this.setPageOptions = function() {
        return [[10, 25, 50, 100, 200, 500, 1000], [10, 25, 50, 100, 200, 500, 1000]];
    };


    /**
     * Set the search delay
     */
    this.setSearchDelay = function() {
        return this.table.data('search-delay') !== undefined ? this.table.data('search-delay') : 1500;
    };


    /**
     * Get the sort order
     *
     * @returns {*[]}
     */
    this.setSortOrder = function() {
        var column      = this.table.data('sort') !== undefined ? this.table.data('sort') : 0;
        var columnOrder = this.table.data('sorting') !== undefined ? this.table.data('sorting') : 'desc';

        return [[column, columnOrder]];
    };


    /**
     * Get the state of the data tables
     *
     * @returns {boolean}
     */
    this.setState = function() {

        // Individual Table State
        if (this.table.data('save-state') !== undefined) {
            return this.table.data('save-state');
        }

        // Global Config State
        if (AppDataTableState) {
            return AppDataTableState;
        }

        // Fallback
        return false;
    };


    /**
     * Get the language text
     *
     * @returns {{paginate: {next: string, previous: string}, searchPlaceholder: string}}
     */
    this.setLanguage = function() {
        return {
            'paginate' : {
                'next'     : '<i class="mdi-navigation-arrow-forward"></i>',
                'previous' : '<i class="mdi-navigation-arrow-back"></i>'
            },
            searchPlaceholder: "Search records..."
        }
    };


    /**
     * Get the columns
     *
     * @returns {Array}
     */
    this.setColumns = function() {

        // Headings
        var headings = this.table.find('th');
        var columns  = [];

        // Work out the column names, classes and sort state
        if (headings !== undefined && headings.length > 0) {
            $.each(headings, function(index, heading) {
                var columnName  = $(heading).html().replace(/\s+/g, '-').toLowerCase();
                var columnClass = $(heading).prop('class').toLowerCase();
                var isSortable = true;

                // Handle the non sortable columns
                if (/actions/i.test(columnClass) || /not-sortable/i.test(columnClass)) {
                    isSortable = false;
                }

                // Add the columns to the datatables
                columns.push({
                    "className" : columnName + ' ' + columnClass,
                    "orderable" : isSortable
                });
            });
        }

        return columns;
    };


    /**
     * Get the options for the data table
     */
    this.setOptions = function() {

        var ready = false;

        var options = {
            'lengthMenu'  : this.setPageOptions(),
            'pageLength'  : this.setPageLength(),
            'dom'         : '<"dataTables_header"lfr>t<"dataTables_footer"ip>',
            'deferRender' : true,
            'responsive'  : true,
            'autoWidth'   : false,
            'searchDelay' : this.setSearchDelay(),
            'stateSave'   : this.setState(),
            'order'       : this.setSortOrder(),
            "columns"     : this.setColumns(),
            'language'    : this.setLanguage(),
            "fnDrawCallback": function() {

                if (!ready) {
                    $.event.trigger('datatable.ready');
                    ready = true;
                } else {
                    $.event.trigger('datatable.change');
                }

            }
        };

        if (this.table.data('ajax')) {
            options.ajax       = this.table.data('ajax');
            options.processing = true;
            options.serverSide = true;
        }

        return options;
    };


    /**
     * Handle site filter
     *
     * Listen for the data table
     * ajax pre-flight event and append the data
     */
    this.handleSiteFilter = function()
    {
        var dt = this.dataTable;

        $(document).on('change', '#dt-site-filter', function() {
            dt.on('preXhr.dt', function(e, settings, data) {
                data.site_ids = $('#dt-site-filter').val();
            }).ajax.reload();
        });
    };


    /**
     * Handle form filters
     *
     * Listen for the data table
     * ajax pre-flight event and append the data
     */
    this.handleFormFilter = function()
    {
        $(document).off('change', '.dataTables_filter select').on('change', '.dataTables_filter select', function() {

            var self = this;
            var val = $(self).val();
            var key = $(self).attr('name');

            var dt = $(this).closest('.dataTables_wrapper').find('.dataTable').DataTable();

            dt.on('preXhr.dt', function(e, settings, data) {
                data[key] = val;
            }).ajax.reload();
        });
    };


    /**
     * Datatable events
     *
     * Listen for the data table
     * ajax pre-flight event and append the data
     */
    this.events = function(element)
    {
        $(document).on('datatable.ready', function(event, data) {
            $(element).each(function() {
                var filter = $(this).closest('.dataTables_wrapper').find('.dataTables_filter');
                if ($(this).data('filter') !== undefined && !filter.find('.filter-label').length) {
                    filter.prepend('<div class="filter-label">' + $($(this).data('filter')).html() + '</div>');
                }
            });
        });
    }
};


/**
 * Reload the datatables on ajax content reload
 */
$(document).on('contentReloaded', function(event, element) {
    var table = $(element).closest('table');
    if (table && table.hasClass('data-tables')) {
        App.jquery.handleDataTables();
    }
});
