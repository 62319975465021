/**
 * App pageHead Object
 *
 * @type object
 */
App.pageHead = {

    /**
     * Constructor Function
     * Boot function
     */
    init: function() {
        this.watch();
    },

    /*
     * Jquery watch events
     */
    watch: function() {

        // Default variables
        var page_head = $('#page-head');
        var self = this;

        // Apply the sticky action class
        page_head.find('.container-fluid > .row').addClass('sticky-actions');

        $(window).scroll(function() {
            self.test();
        });

        $(document).on('app.sidebar.opened', function() {
            self.test();
        });

        $(document).on('app.sidebar.closed', function() {
            self.test();
        });

        $(window).resize(function() {
            self.test();
        });
    },

    /*
     * Make the page title sticky
     * @return void
     */
    stick: function () {
        $('#page-head').height($('#page-head').height());
        $('#page-head .sticky-actions').addClass('affix');
    },

    /*
     * Unstick the page title
     * @return void
     */
    unstick: function () {
        $('#page-head').removeAttr('style');
        $('#page-head .sticky-actions').removeClass('affix');
    },

    /*
     * Test for whether we should make the page title sticky
     * @return void
     */
    test: function() {
        if ($(window).scrollTop() >= this.getHeight()) {
            App.pageHead.stick();
        } else {
            App.pageHead.unstick();
        }
    },

    /**
     * Get breadcrumb height
     *
     * @returns {*|jQuery}
     */
    getHeight: function() {
        return $('#page-head').outerHeight();
    },

    /**
     * Set breadcrumb height
     */
    setHeight: function() {

    }
};