/**
 * App confirm Object
 *
 * @type object
 */
App.confirm = {

    /**
     * The modal object
     */
    modal: null,

    /**
     * The element the modal was triggered from
     */
    ele: null,


    /**
     * Constructor Function
     *
     * Boot function
     */
    init: function() {
        this.modal = App.modal;
        this.modal.init();
        this.modal.hideHeader();
        this.modal.showFooter();
        this.bindEvents();
        this.handleConfirm();
        this.modal.primaryBtn.html('Yes');
        this.modal.secondaryBtn.html('No');
        this.modal.secondaryBtn.show();

        return this;
    },


    /**
     * Bind the object events
     */
    bindEvents: function() {
        $(document).on('modalPrimary', $.proxy(this.handlePrimaryClick, this));
        $(document).on('modalSecondary', $.proxy(this.handleSecondaryClick, this));
        $(document).on('modalClose', $.proxy(this.handleCloseClick, this));
    },


    /**
     * Confirm icon
     *
     * @param icon string
     */
    icon: function(icon) {
        this.modal.body.append('<div class="confirm-icon"><i class="' + icon + '"</i></div>');
    },


    /**
     * Confirm title
     *
     * @param title string
     */
    title: function(title) {
        this.modal.body.append('<div class="confirm-title">' + title + '</div>');
    },


    /**
     * Confirm message
     *
     * @param message string
     */
    message: function(message) {
        this.modal.body.append('<div class="confirm-message">' + message + '</div>');
    },


    /**
     * Set the original element
     *
     * @param element
     */
    element: function (element) {
        this.ele = element;
    },


    /**
     * Show function
     */
    show : function() {
        this.modal.show();
    },


    /**
     * Hide function
     */
    hide: function() {
        this.modal.hide();
    },


    /**
     * Handle the primary click
     */
    handlePrimaryClick: function(element) {
        this.modal.primaryBtn.addClass('disabled');
        this.modal.secondaryBtn.addClass('disabled');
        this.modal.primaryBtn.text('Please Wait...');

        if (this.ele.is('form')) {
            this.ele.submit();
            return true;
        }

        if (this.ele.closest('form').length) {
            this.ele.closest('form').submit();
            return true;
        }

        return false;
    },


    /**
     * Handle the secondary click
     */
    handleSecondaryClick: function(element) {
        //App.log('Hey you fired the secondary trigger');
        App.confirm.hide();
    },


    /**
     * Handle the close click
     */
    handleCloseClick: function(element) {
        //App.log('Hey you fired the close trigger');
    },


    /**
     * Handle confirm
     */
    handleConfirm: function() {
        this.modal.container.addClass('modal-confirm');
    }
};
